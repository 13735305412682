/* globals TimeMachine */

import { breakpoints } from '../variables.yml';

import Viewer from 'viewerjs/dist/viewer.esm';

import './vendor/ajax.min.js';
import './vendor/time-machine.js';
// import './vendor/search.js';
import './algolia/search.js';
import { setupShareLinks } from './vendor/setup-share-links.js';
import EmblaCarousel from 'embla-carousel';
import ClassNames from 'embla-carousel-class-names';

import { getScrollY, doToggle, doSearchToggle } from './helpers.js';

import 'object-fit-polyfill';

export * from './CookieConsentManager.js';

( () => {
	// Search
	const search_toggle = document.querySelector( '.header__search' );
	const search_box = document.getElementById( 'js-search' );
	const search_input = document.getElementById( 'js-search-input' );
	const search_toggles = document.querySelectorAll( '.search-toggler' );

	search_toggle.addEventListener( 'click', () => {
		window.setTimeout( () => {
			search_input.getElementsByTagName( 'input' )[0].focus();
		}, 400 );
	} );

	window.addEventListener( 'click', ( e ) => {
		if ( e.target.classList.contains( 'search-toggler' ) ) {
			doSearchToggle( e.target, 'search-toggled' );
		} else if ( !e.target.closest( '.search-box' ) && document.body.classList.contains( 'search-toggled' ) ) {
			search_box.classList.remove( 'search-toggled' );
			document.body.classList.remove( 'search-toggled' );
			search_toggles.forEach( search_toggle => {
				search_toggle.classList.toggle( 'toggler--toggled', !search_toggle.classList.contains( 'toggler--toggled' ) );
			} );
		}
	} );

	window.addEventListener( 'keyup', ( e ) => {
		if ( e.key === 'Escape' ) {
			doToggle( e.target, 'search-toggled' );
		}
	} );
} )();

( () => {
	window.addEventListener( 'click', ( event ) => {
		if ( !event.target.classList.contains( 'toggler' ) ) {
			return;
		}
		doToggle( event.target );
	} );
} )();

( () => {
	// Header
	const header = document.querySelector( '.header' );
	const dy = 5;
	let y0 = 0;

	window.addEventListener( 'scroll', () => {
		handleScroll();
	}, false );

	window.addEventListener( 'resize', () => {
		handleClasses( getScrollY() );
	} );

	function handleClasses( y ) {
		if ( y > 10 ) {
			header.classList.remove( 'header--top' );
			header.classList.add( 'header--scrolling' );
			y > y0 ? header.classList.add( 'header--hidden' ) : header.classList.remove( 'header--hidden' );
		} else {
			header.classList.add( 'header--top' );
		}
		y0 = y;
	}

	function handleScroll() {
		const y = getScrollY();
		if ( Math.abs( y0 - y ) <= dy ) {
			return;
		}
		handleClasses( y );
	}
} )();

( () => {
	// Time Machine
	const content_receptacle = document.querySelector( '.main-content' );
	const nav = document.querySelector( '.header__nav' );
	const nav_toggle = document.querySelector( '.header__toggle' );
	const nav_items = document.querySelectorAll( '[data-tm-slug]' );
	const search_box = document.getElementById( 'js-search' );
	const search_button = document.querySelector( '.header__search' );

	new TimeMachine( {
		default_ajax_receptacle_id: 'main-content',
		beforeNewPageLoad: beforeNewPageLoad,
		afterNewPageLoad: afterNewPageLoad
	} );

	function beforeNewPageLoad( updated_receptacle_id, source_id, window_location, loadPage ) {
		if ( updated_receptacle_id !== 'main-content' && ( window_location === null || window_location.split( '?' )[0].match( /\/projects\/?$/i ) !== null ) ) {
			preparePageForLoad();
			destroyPageScript();
			loadPage();
			return;
		}
		content_receptacle.classList.add( 'loading' );
		preparePageForLoad();
		destroyPageScript();
		loadPage();
	}

	function getCookie( cname ) {
		/* eslint-disable snakecasejs/snakecasejs */
		const name = cname + '=';
		const decodedCookie = decodeURIComponent( document.cookie );
		const ca = decodedCookie.split( ';' );
		for ( let i = 0; i < ca.length; i++ ) {
			let c = ca[i];
			while ( c.charAt( 0 ) === ' ' ) {
				c = c.substring( 1 );
			}
			if ( c.indexOf( name ) === 0 ) {
				return c.substring( name.length, c.length );
			}
		}
		return '';
	}

	function afterNewPageLoad( updated_receptacle_id, page_data ) {
		if ( page_data ) {
			if ( typeof page_data.slug !== 'undefined' ) {
				for ( let i = 0; i < nav_items.length; i += 1 ) {
					const link_slug = nav_items[i].getAttribute( 'data-tm-slug' );
					nav_items[i].classList.toggle( 'header__link--selected', page_data.slug === link_slug );
				}
			}
			if ( page_data.show_cookie_banner !== '' && !getCookie( 'ten4_cookie_consent' ) ) {
				document.body.classList.add( 'cookie-banner-open' );
			} else {
				document.body.classList.remove( 'cookie-banner-open' );
				document.body.classList.remove( 'cookie-manager-open' );
			}
		}
		if ( updated_receptacle_id === 'main-content' ) {
			// window.scrollTo( 0, 0 );
		}
		content_receptacle.classList.remove( 'loading' );
		window.runPageScript();
		const search_box = document.getElementById( 'js-search' );
		const search_toggles = document.querySelectorAll( '.search-toggler' );
		search_box.classList.remove( 'search-toggled' );
		document.body.classList.remove( 'search-toggled' );
		search_toggles.forEach( search_toggle => {
			search_toggle.classList.toggle( 'toggler--toggled', document.body.classList.contains( 'search-toggled' ) );
		} );
		try {
			// This can fail and break Time Machine if Google Analytics is
			// blocked by the browser, so let's wrap it in a try/catch.
			window.dataLayer.push( {
				event: 'pageview',
				page: {
					page_title: document.title,
					page_path: window.location.pathname
				}
			} );
		} catch ( error ) {
			// Do nothing.
		}
	}

	function preparePageForLoad() {
		nav.classList.remove( 'toggled' );
		document.body.classList.remove( 'toggled' );
		nav_toggle.classList.remove( 'toggler--toggled' );
		search_button.classList.remove( 'toggler--toggled' );
		search_box.classList.remove( 'toggled' );
		document.body.classList.remove( 'cookie-banner-open' );
		document.body.classList.remove( 'cookie-manager-open' );
	}

	function destroyPageScript() {
		window.Goppion = null;
	}
} )();

( () => {
	// Scrollto
	const anchors = document.querySelectorAll( '[data-scroll-to]');
	if ( !anchors ) {
		return;
	}
	anchors.forEach( anchor => {
		anchor.addEventListener( 'click', ( e ) => {
			e.preventDefault();
			const scroll_to_el = document.getElementById( e.target.dataset.scrollTo );
			scroll_to_el.scrollIntoView();

		} );
	} );
} )();

function contentEasing() {
	// Animation for content blocks
	const revealables = document.querySelectorAll( '.revealable' );
	if ( typeof window.IntersectionObserver === 'undefined' ) {
		for ( let i = 0; i < revealables.length; i++ ) {
			revealables[i].classList.add( 'revealable--revealed' );
		}
		return;
	}
	let initial_page_load = true;
	const io = new IntersectionObserver( ( entries, observer ) => {
		entries.forEach( entry => {
			if ( entry.isIntersecting ) {
				if ( initial_page_load ) {
					const delay = parseInt( entry.target.getAttribute( 'data-revealable-delay' ) || '0', 10 ) / 1000;
					entry.target.style.transitionDelay = delay.toString() + 's';
				}
				entry.target.classList.add( 'revealable--revealed' );
				observer.unobserve( entry.target );
			}
		} );
		initial_page_load = false;
	}, {
		rootMargin: '50px 0px -50px 0px',
	} );
	revealables.forEach( ( target ) => {
		io.observe( target );
	} );
}

function lazyItems() {
	// Lazy loading
	const lazy_items = document.querySelectorAll( '[data-lazy-iframe], [data-lazy-src]' );
	if ( !lazy_items ) {
		return;
	}

	function restoreItem( element ) {
		if ( element.hasAttribute( 'data-lazy-iframe' ) ) {
			const src = element.getAttribute( 'data-lazy-iframe' );
			const iframe = document.createElement( 'iframe' );
			iframe.src = src;
			element.classList.remove( 'lazy--unloaded' );
			element.classList.add( 'lazy--loaded' );
			element.appendChild( iframe );
		} else {
			const src = element.getAttribute( 'data-lazy-src' );
			const image = document.createElement( 'img' );
			image.addEventListener( 'load', () => {
				element.classList.remove( 'lazy--unloaded' );
				element.classList.add( 'lazy--loaded' );
			} );
			image.src = src;
			image.alt = '';
			element.appendChild( image );
		}
	}

	if ( typeof window.IntersectionObserver === 'undefined' ) {
		for ( let i = 0; i < lazy_items.length; i++ ) {
			restoreItem( lazy_items[i] );
		}
	} else {
		const observer = new IntersectionObserver( ( entries ) => {
			for ( let i = 0; i < entries.length; i++ ) {
				if ( !entries[i].isIntersecting ) {
					continue;
				}
				restoreItem( entries[i].target );
				observer.unobserve( entries[i].target );
			}
		}, {
			// Enough vertical margin so it loads before we scroll there, hopefully.
			rootMargin: '0px 0px 0px 0px',
		} );
		for ( let i = 0; i < lazy_items.length; i++ ) {
			observer.observe( lazy_items[i] );
		}
	}
}

function buildLightboxes() {
	// Lightbox
	const images = document.querySelectorAll( '.lightbox-images' );
	if ( !images ) {
		return;
	}
	images.forEach( container => {
		new Viewer( container, {
			minZoomRatio: 0.5,
			movable: false,
			navbar: 0,
			toolbar: {
				zoomIn: 0,
				zoomOut: 0,
				oneToOne: 0,
				reset: 0,
				prev: 1,
				play: 0,
				next: 1,
				rotateLeft: 0,
				rotateRight: 0,
				flipHorizontal: 0,
				flipVertical: 0,
			},
			tooltip: false,
			transition: false,
			url( image ) {
				return image.getAttribute( 'data-lightbox-url' );
			}
		} );
	} );
}

function handleSearchBox() {
	const query = [];
	const toggler = document.querySelector( '.search-toggler' );
	for ( const [key, value] of new URLSearchParams( window.location.search ) ) {
		query.push( key );
	}
	if ( query.indexOf( 'SEARCH_dev[query]' ) !== -1 || query.indexOf( 'SEARCH_staging[query]' ) !== -1 || query.indexOf( 'SEARCH_production[query]' ) !== -1 ) {
		doSearchToggle( toggler, 'search-toggled' );
	}
}

function initEmbla() {
	const nodes = document.querySelectorAll( '.embla__carousel' );
	if ( !nodes ) {
		return;
	}
	const options = { loop: false };

	nodes.forEach( node => {
		const thumbs_node = node.nextElementSibling;
		const thumbs_viewport = thumbs_node ? thumbs_node.querySelector( '.embla__thumbs-viewport' ) : null;
		const embla_api = EmblaCarousel( node, options, [
			ClassNames(),
		] );
		const embla_api_thumbs = EmblaCarousel( thumbs_viewport, {} );
		const toggle_embla_ready = ( embla_api, event ) => {
			const is_resize_event = event === 'resize';
			const toggle_class = is_resize_event ? 'remove' : 'add';
			node.classList[toggle_class]( 'embla--is-ready' );
			if ( is_resize_event ) {
				embla_api.reInit();
			}
		};
		const remove_thumb_btns_click_handlers = addThumbBtnsClickHandlers(
			embla_api,
			embla_api_thumbs,
		);
		const remove_toggle_thumb_btns_active = addToggleThumbBtnsActive(
			embla_api,
			embla_api_thumbs,
		);

		embla_api_thumbs
			.on( 'destroy', remove_thumb_btns_click_handlers )
			.on( 'destroy', remove_toggle_thumb_btns_active );
	} );
}

function addThumbBtnsClickHandlers( embla_api, embla_api_thumbs ) {
	const slides_thumbs = embla_api_thumbs.slideNodes();
	const scroll_to_index = slides_thumbs.map(
		( _, index ) => () => embla_api.scrollTo( index ),
	);

	slides_thumbs.forEach( ( slide_node, index ) => {
		slide_node.addEventListener( 'click', scroll_to_index[index], false );
	} );

	return () => {
		slides_thumbs.forEach( ( slide_node, index ) => {
			slide_node.removeEventListener( 'click', scroll_to_index[index], false );
		} );
	};
}

function addToggleThumbBtnsActive( embla_api, embla_api_thumbs ) {
	const slides_thumbs = embla_api_thumbs.slideNodes();
	const toggle_thumb_btns_state = () => {
		embla_api_thumbs.scrollTo( embla_api.selectedScrollSnap() );
		const previous = embla_api.previousScrollSnap();
		const selected = embla_api.selectedScrollSnap();
		slides_thumbs[previous].classList.remove( 'embla__thumbs-slide--selected' );
		slides_thumbs[selected].classList.add( 'embla__thumbs-slide--selected' );
	};

	embla_api.on( 'select', toggle_thumb_btns_state );
	embla_api_thumbs.on( 'init', toggle_thumb_btns_state );

	return () => {
		const selected = embla_api.selectedScrollSnap();
		slides_thumbs[selected].classList.remove( 'embla__thumbs-slide--selected' );
	};
}

( () => {
	// Video posters
	const videos = document.querySelectorAll( '.content-block__video' );

	if ( !videos ) {
		return;
	}

	function buildVideoPlayer( container ) {
		const embed_url = container.getAttribute( 'data-embed-url' );
		const iframe = document.createElement( 'iframe' );
		iframe.setAttribute( 'width', 1640 );
		iframe.setAttribute( 'height', 923 );
		iframe.setAttribute( 'src', embed_url );
		iframe.setAttribute( 'title', 'Embedded video content' );
		iframe.setAttribute( 'frameborder', 0 );
		iframe.setAttribute( 'allowfullscreen', true );
		iframe.setAttribute( 'allow', 'autoplay' );
		container.classList.add( 'flex-container' );
		container.classList.remove( 'content-block__video--with-poster' );
		container.classList.add( 'content-block__video--played' );
		container.innerHTML = '';
		container.appendChild( iframe );
	}

	function handleVideoClick( container ) {
		return ( event ) => {
			if ( event.preventDefault ) {
				event.preventDefault();
			} else {
				event.returnValue = false;
			}
			buildVideoPlayer( container );
		};
	}

	for ( let i = 0; i < videos.length; i++ ) {
		videos[i].addEventListener( 'click', handleVideoClick( videos[i] ) );
	}

} )();

window.contactRecaptchaCallback = () => {
	const form = document.getElementById( 'contact-form' );
	if ( !form ) {
		return;
	}
	form.submit();
};

window.runPageScript = () => {
	lazyItems();
	contentEasing();
	handleSearchBox();
	initEmbla();

	if ( typeof window.Goppion === 'function' ) {
		window.Goppion( {
			buildLightboxes: buildLightboxes,
			setupShareLinks: setupShareLinks,
		} );
	}
};
window.runPageScript();
